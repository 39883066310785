/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --button-padding: 1.6rem 6rem;
    --button-color: #{$white};
    --button-background: #{$black};
    --button-border: #{$black};
    --button-hover-background: transparent;
    --button-hover-color: #{$black};
    --button-hover-border: #{$black};
    --button-border-width: 1px;
    --button-font-size: 1.1rem;
    --button-font-weight: 600;
}

.Button {
    @include button;
    border-radius: var(--theme-border-radius-button);
    text-transform: uppercase;
    letter-spacing: var(--heading-letter-spacing);
}

button {
    background-color: transparent;
    letter-spacing: var(--heading-letter-spacing);
    text-transform: uppercase;
}
