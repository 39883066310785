:root {
    --hero-height: 70vh;
    #desc-only {display: none;}
    @include desktop { 
        --hero-height: 80vh;
        #desc-only {display: block;}
    }  
}

.cms-module-hero {
    height: var(--hero-height);
    @include desktop { padding-top: 8rem; }
    .bg-1-1 {background: var(--picment-color-1-1);}
    .bg-1-2 {background: var(--picment-color-1-2);}
    .bg-1-3 {background: var(--picment-color-1-3);}
    .bg-2-1 {background: var(--picment-color-2-1);}
    .bg-2-2 {background: var(--picment-color-2-2);}
    .bg-2-3 {background: var(--picment-color-2-3);}
    .bg-3-1 {background: var(--picment-color-3-1);}
    .bg-3-2 {background: var(--picment-color-3-2);}
    .bg-3-3 {background: var(--picment-color-3-3);}
    .bg-4-1 {background: var(--picment-color-4-1);}
    .bg-4-2 {background: var(--picment-color-4-2);}
    .bg-4-3 {background: var(--picment-color-4-3);}
    .btn-box-1-1 {box-shadow: 5px 5px 0px var(--picment-color-1-1);}
    .btn-box-1-2 {box-shadow: 5px 5px 0px var(--picment-color-1-2);}
    .btn-box-1-3 {box-shadow: 5px 5px 0px var(--picment-color-1-3);}
    .btn-box-2-1 {box-shadow: 5px 5px 0px var(--picment-color-2-1);}
    .btn-box-2-2 {box-shadow: 5px 5px 0px var(--picment-color-2-2);}
    .btn-box-2-3 {box-shadow: 5px 5px 0px var(--picment-color-2-3);}
    .btn-box-3-1 {box-shadow: 5px 5px 0px var(--picment-color-3-1);}
    .btn-box-3-2 {box-shadow: 5px 5px 0px var(--picment-color-3-2);}
    .btn-box-3-3 {box-shadow: 5px 5px 0px var(--picment-color-3-3);}
    .btn-box-4-1 {box-shadow: 5px 5px 0px var(--picment-color-4-1);}
    .btn-box-4-2 {box-shadow: 5px 5px 0px var(--picment-color-4-2);}
    .btn-box-4-3 {box-shadow: 5px 5px 0px var(--picment-color-4-3);}
    .c-1-1 {color: var(--picment-color-1-1);}
    .c-1-2 {color: var(--picment-color-1-2);}
    .c-1-3 {color: var(--picment-color-1-3);}
    .c-2-1 {color: var(--picment-color-2-1);}
    .c-2-2 {color: var(--picment-color-2-2);}
    .c-2-3 {color: var(--picment-color-2-3);}
    .c-3-1 {color: var(--picment-color-3-1);}
    .c-3-2 {color: var(--picment-color-3-2);}
    .c-3-3 {color: var(--picment-color-3-3);}
    .c-4-1 {color: var(--picment-color-4-1);}
    .c-4-2 {color: var(--picment-color-4-2);}
    .c-4-3 {color: var(--picment-color-4-3);}

    .hero-columns{
        display: grid;
        grid-template-columns: auto;
        text-align: center;
        margin: auto;

        @include desktop {
            grid-template-columns: 50% 50%;
            max-width: 65%;
            text-align: left;
        }
    }

    .hero-overlay {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }

    .hero-title-left {
        font-size: 8rem;
        font-family: var(--display-font);
        margin: 0 0 2rem 0;
        line-height: 1;

        @include desktop {
            max-width: 60%;
        }
    }

    .hero-title {
        font-size: 5rem;
        font-family: var(--display-font);
        margin: 0 auto;
        line-height: 1;

        @include desktop {
            font-size: 8rem;
        }
    }

    .hero-subtitle {
        font-size: 3rem;
        line-height: 6rem;
        font-family: 'WindSong';
        font-weight: 100;
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;

        @include desktop {
            font-size: 4rem;
        }
    }

    .hero-title-h2{
        font-size: 3rem;
        margin: 0;
        line-height: 3;

        @include desktop {
            line-height: 2;
            font-size: 4rem;
        }
    }

    .hero-title-p {
        margin: auto auto;
    }

    .hero-title-p-xs{
        font-size: 1rem;
    }

    .hero-box{
        padding:2rem 3rem;
        width: fit-content;
        margin:auto;

        @include desktop{
            padding: 2rem 6rem;
        }
    }

    .cms-button { 
        display:block;
        width: 95%;
        margin: 1rem auto;

        @include desktop {
            display: inline-flex;
            width: fit-content;
            margin: 4rem calc(var(--cms-button-spacing) / 2) 0;
        }
    }

    .btn-space {
        padding-top: 4rem;

        @include desktop {
            padding-top: 0;
        }
    }

    .hero-departments {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-family: var(--display-font);

        a {
            display: flex;
            font-size: 6rem;
            text-decoration: none;

            &:hover {
                color: var(--theme-color-3);
            }
        }
    }

    &-video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;

        &::-internal-media-controls-overlay-cast-button {
            display: none;
        }
        
        &-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
