/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
body .NavigationTabs {
    @include mobile {
        &-LogoBtn {
            margin-right: 0;
        }

        &-LogoWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-LogoImg {
            max-width: 140px;
            width: 140px;
            padding-bottom: 20px !important;
        }

        &-Button {
            margin-right: 0;

            &:first-child {
                margin-top: -10px;

                &::after {
                    content: "Menu";
                    position: absolute;
                    bottom: -0.1em;
                    font-size: 10px;
                    text-transform: capitalize;
                    color: #000;
                }

                // &:has(.NavigationTabs-Icon_isActive)::after {
                //     content: "";
                //     display: none;
                // }
            }            
        }

        &-Nav {
            > button {
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
}
