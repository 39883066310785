/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Breadcrumb {
    // Will not overwrite default if placed in :root
    --breadcrumbs-divider-color: #b2b2b2;
    --breadcrumbs-color: var(--body-content-color);

    @include mobile {
        font-size: 1.2rem;
    }

    &:not(:last-child) {
        &::after {
            border-width: 0 1px 0 0;
            margin: 0;
            top: 50%;
            transform: translateY(-50%) rotate(25deg);
            height: 10px;
            width: 10px;
            position: absolute;
            transform-origin: center;
            margin-top: -2px;
            border-color: var(--breadcrumbs-divider-color);
        }
    }

    &:last-child {
        --breadcrumbs-active-color: #6e6e6e;
    }
}
