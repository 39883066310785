/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
/* stylelint-disable length-zero-no-unit */
:root {
    --header-width: 100%;
    --header-v-padding: var(--content-v-padding);
    --header-nav-height: 80px;
    --header-height: var(--header-nav-height);
    --demo-notice-height: 0px;
    --picment-notice-height: 34px;

    @include desktop {
        --header-total-height: 0px;
    }
    @include tablet {
        --header-nav-height: 50px;
    }
    @include phone {
        --header-nav-height: 50px;
    }
}

body {
    @include mobile {
        /* Needs to be here as Scandi gave it priority by putting it in body on mobile */
        /* stylelint-disable-line */
        --header-sub-height: 0px;

        /* --header-sub-height: 40px; */
        --header-total-height:
            calc(
                var(--header-height)
                + var(--offline-notice-height)
                + var(--demo-notice-height)
                + env(safe-area-inset-top)
                + var(--header-sub-height)
            );

        /* stylelint is disabled here - if "px" is removed the header height calculation breaks */
        &.isHomePage { /* stylelint-disable-line */
            --header-sub-height: 0px;
        }
    }

    /* Needs to be here as Scandi gave it priority by putting it in body on mobile */
    @include tablet {
        --header-nav-height: 60px;
        --header-height: var(--header-nav-height);
    }

    /* Needs to be here as Scandi gave it priority by putting it in body on mobile */
    @include phone {
        --header-nav-height: 50px;
        --header-height: var(--header-nav-height);
    }
}

.Header {
    @include desktop {
        --header-logo-width: 215px;
        --header-logo-height: 55px;

        position: relative;
    }

    @include mobile {
        --header-logo-width: 150px;
        --header-logo-height: 38px;

        z-index: 101;
        box-shadow: 0 5px 15px 5px rgba(0, 0, 0, .1);
    }

    &-Wrapper {
        margin-bottom: 0;

        @include desktop {
            margin-bottom: var(--header-total-height);
        }

        &_name {
            &_cart {
                .Header-Nav {
                    @include mobile {
                        display: none;
                    }
                }
            }
        }

        .TopBar {
            z-index: 1;

            @include mobile {
                top: var(--header-nav-height);
            }

            &-InnerContainer {
                height: 100%;                
                padding: .5rem;
            }

            &-Notice {
                display: grid;
                grid-template-columns: 33% 33% 33%;
                justify-content: space-around;
                flex-wrap: wrap;
                width: 100%;
                max-width: 1400px;
                margin: 0 auto;
                
                @include mobile {
                    width: fit-content;
                    gap: 40px;
                }

                a {
                    color: inherit;
                }

                li {
                    display: flex;
                    margin-bottom: 0;
                    padding-left: 0;
                    font-size: 1.2rem;
                    

                    &::before {
                        content: none;
                    }
                    // &::before {
                    //     content: "•";
                    //     position: static;
                        
                    //     padding: 0;
                    //     margin: 0;
                    //     margin-right: 1.5rem;
                    //     padding-top: 0.25rem;
                      
                    //     font-size: 1rem;
                    // }
                    // &:first-child::before {
                    //     content: none
                    // }
                }


                .TopBar-Trustpilot {
                    &-Container {
                        //filter: grayscale(1);
                        display: flex;
                        gap: 1rem;
                    
                        &::before {
                            position: relative;
                            left: 0;
                            // top: -2px;
                            margin-right: 1rem;
                        }

                        .Trustpilot-Text-Bold {
                            font-weight: bold;
                            padding-right: .4em;
                        }
    
                        //.Trustpilot-Text-Normal {
                        //}
                    }
                    &-Rating {
                        content: url(https://picment.dk/media/badges/trustpilot-star.svg);
                        height: 17px;
                    }
                    
                    &-Logo {
                        content: url(https://picment.dk/media/badges/trustpilot-stars-5.svg);
                        height: 16px;
                        position: relative;
                        top: .1rem;
                    }
                }
            }
        }
    }

    &-Nav {
        width: var(--header-width);
        // max-width: var(--header-width);
        max-width: calc(var(--content-wrapper-width) + 100px);
        padding-left: calc(var(--header-v-padding) / 2);
        padding-right: calc(var(--header-v-padding) / 2);
        margin: 0 auto;

        @include mobile {
            padding: 0 1.4rem;
            height: var(--header-sub-height);
        }

        .NavigationTabs-Button {
            position: absolute;
            left: var(--header-v-padding);
        }

        > * {
            order: 99;
        }

        > .Header-DepartmentMenu {
            order: 1;
            margin-right: 10px;
        }

        > .Header-Search {
            order: 2;
            margin-right: auto;
        }
    }

    &-DepartmentMenu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &-DepartmentTitle {
        margin-left: 1.2rem;
    }

    &-DepartmentTitleLink {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: 500;
    }

    &-LogoWrapper {
        right: unset;
        left: 50%;
        transform: translateX(-50%);

        .Image.Logo {
            height: 100% !important;
        }
    }

    &-Title {
        @include mobile {
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.2rem;
            margin: {
                top: 0 !important;
                bottom: 0;
            };
        }
    }

    &-Wishlist {
        height: 30px;
        margin-left: 10px;

        @at-root .isCheckout & {
            display: none;
        }

        @include mobile {
            display: none;
        }
    }

    &-MyWishlistTitle {
        display: none;
    }

    &-MyAccount {
        @include mobile {
            display: none;
        }
    }

    &-Button {
        &_type {
            &_menu {
                background-image: url('icons/burger.svg');
                background-repeat: no-repeat;
                background-size: 40px 40px;
                background-position: center;

                @include desktop {
                    width: 35px;
                }

                &::before,
                &::after {
                    display: none;
                }

                &.NavigationTabs-Icon_isActive {
                    background-image: url('icons/close.svg');
                    transform: none;
                }
            }

            &_account {
                background-image: url('icons/user.svg');
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-size: 40px 40px;
            }

            &_wishlist {
                background-image: url('icons/heart.svg');
                width: 30px;
                height: 30px;
                background-size: 40px 40px;
                background-position: center;
                display: block;
                opacity: 1;
                background-repeat: no-repeat;
            }

            &_minicart {
                padding: 0;
                margin-left: 10px;

                @include mobile {
                    background-image: url('icons/cart.svg');
                    background-repeat: no-repeat;
                    background-size: 40px 40px;
                    background-position: center;
                    top: 0;
                    height: 30px;
                    width: 30px;
                }
            }

            &_back {
                @include mobile {
                    position: fixed;
                    top: 0;
                    background: #fff;
                    left: calc(1.4rem + 40px);
                    border-radius: 50%;
                    height: 50px;
                    width: 40px;
                    padding: 0;
                    background-image: url('icons/back.svg');
                    background-repeat: no-repeat;
                    background-size: 40px 40px;
                    background-position: center;

                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }

            &_close {
                @include mobile {
                    position: fixed;
                    top: 0;
                    background: #fff;
                    left: calc(1.4rem + 40px);
                    border-radius: 50%;
                    height: 50px;
                    width: 40px;
                    padding: 0;
                    background-image: url('icons/close.svg');
                    background-repeat: no-repeat;
                    background-size: 40px 40px;
                    background-position: center;
                    z-index: 21;

                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }

            &_share,
            &_share.Header-Button_isVisible {
                @include mobile {
                    display: block;
                    position: fixed;
                    top: 0;
                    background: #fff;
                    left: calc(1.4rem + 40px);
                    border: none;
                    height: 50px;
                    width: 40px;
                    padding: 0;
                    background-image: url('icons/share.svg');
                    background-repeat: no-repeat;
                    background-size: 40px 40px;
                    background-position: center;
                    margin-left: 0;
                    opacity: 1;

                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }

            &_clear {
                @include mobile {
                    position: fixed;
                    bottom: 20px;
                    right: 20px;
                    background: #fff;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, .1);

                    &::before {
                        top: 16px;
                        left: 14px;
                    }

                    &::after {
                        left: 16px;
                        top: 11px;
                    }
                }
            }

            &_edit {
                @include mobile {
                    position: fixed;
                    bottom: 100px;
                    right: 20px;
                    background: #fff;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px !important;
                    opacity: 1 !important;
                    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, .1);
                    background-image: url('icons/edit.svg');
                    background-repeat: no-repeat;
                    background-size: 40px 40px;
                    background-position: center;
                    font-size: 0;

                    &::before {
                        top: 16px;
                        left: 14px;
                    }

                    &::after {
                        left: 16px;
                        top: 11px;
                    }
                }
            }

            &_ok {
                @include mobile {
                    position: fixed;
                    bottom: 100px;
                    right: 20px;
                    background: #fff;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px !important;
                    opacity: 1 !important;
                    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, .1);

                    &::before {
                        top: 16px;
                        left: 14px;
                    }

                    &::after {
                        left: 16px;
                        top: 11px;
                    }
                }
            }
        }
    }

    &-MinicartButtonWrapper {
        margin: 0;
        padding: 0;
    }

    &-MinicartIcon {
        background-image: url('icons/cart.svg');
        background-repeat: no-repeat;
        background-size: 40px 40px;
        background-position: center;
    }

    &-MinicartItemCount {
        top: -5px;
        left: unset;
        right: -5px;
        transform: none;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        font-size: 1.1rem;
    }

    &_name {
        &_default {
            @include mobile {
                height: var(--header-height);
            }
        }

        &_popup {
            .Header-Button_type_close {
                @at-root .isProductPage & {
                    @include mobile {
                        display: none;
                    }
                }
            }
        }
    }

    .isHomePage &,
    .isBlogOverview &,
    .Header-Wrapper_name_posts-details &,
    .isCmsPage &,
    .isCategoryPageCmsOnly & {
        @include desktop {
            background-color: transparent;
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .4) 100%);
            border: none;

            &-LogoWrapper {
                .Image {
                    &-Image {
                        filter: invert(1);
                    }
                }
            }

            &-MinicartIcon,
            &-Button_type_wishlist,
            &-Button_type_account,
            &-Button_type_menu,
            &-DepartmentMenu .DepartmentMenu-Icon,
            &-DepartmentTitleLink {
                filter: invert(1);
            }
        }
    }

    .isCheckout & {
        &-Search {
            visibility: hidden;
        }
    }
}

@media (min-width: 673px) and (max-width: 900px) {
    .Header-Wrapper .TopBar .TopBar-InnerContainer .TopBar-Notice li:is(:last-child, :nth-last-child(2)) {
        display: none;
    }
}

@media (max-width: 672px) {
    .Header-Wrapper .TopBar .TopBar-InnerContainer .TopBar-Notice li:not(:first-child, :nth-child(2)) {
        display: none;
    }  
}

/* New menu changes */
.picment_dk_store_view .Header {

    &::after {
        content: "";
        display: block;
        height: 8px;
        @include desktop {
            height: 13px;
        }        
        width: 100%;
        position: absolute;
        bottom: 0;
        // z-index: 20;
        z-index: unset;
        
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
        
        pointer-events: none;
    }
    &-Wrapper {
        background: #fff;
        z-index: 2;
    }

    &-Nav {
        // max-width: unset;
        max-width: var(--content-wrapper-width);
        width: calc(100% - 100px);
        padding: 0;
        justify-content: flex-start;
        z-index: unset;
    }
    &-LogoWrapper {
        left: 0;
        right: 0;
        position: absolute;
        transform: unset;
        // margin-left: calc(100vw / 9);
        // margin-left: auto;
        // margin-right: auto;
        order: 2;
        // transform: translateX(-9px);

        /* STUPID, I KNOW */
        // @media (min-width: 1180px) {
        //     margin-left: calc(100vw / 12);
        // }
        // @media (min-width: 1250px) {
        //     margin-left: calc(100vw / 13);
        // }
        // @media (min-width: 1800px) {
        //     margin-left: calc(100vw / 15);
        // }
        // @media (min-width: 1900px) {
        //     margin-left: calc(100vw / 16.5);
        // }
        // @media (min-width: 2100px) {
        //     margin-left: calc(100vw / 17);
        // }
        // @media (min-width: 2300px) {
        //     margin-left: calc(100vw / 20);
        // }
        // @media (min-width: 2500px) {
        //     margin-left: calc(100vw / 13);
        // }
        
        // position: inherit;

        img {
            filter: none !important
        }
    }

    &-MyAccount {
        // margin-left: auto;
        order: 3;
    }

    
}

.picment_dk_store_view.isHomePage .Header,
.picment_dk_store_view.isBlogOverview .Header,
.picment_dk_store_view.Header-Wrapper_name_posts-details .Header,
.picment_dk_store_view.isCmsPage .Header,
.picment_dk_store_view.isCategoryPageCmsOnly .Header {
    @include desktop {
        background-color: transparent;
        background-image: unset;
        // background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .4) 100%);
    }
}

.picment_dk_store_view .Header-Nav > .Header-Search {
    @include desktop {
        order: 3;
        margin-right: 39px;
        margin-left: auto;
    }
}

// .Header_name_cart .Header-LogoWrapper {
//     position: absolute;
//     left: 0;
//     right: 0;
//     margin: auto;
// }