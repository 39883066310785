:root {
    --p-font-size: 1.35rem;
    --p-font-weight: 400;
    --h1-font-size: 5rem;
    --h2-font-size: 3rem;
    --h3-font-size: 1.8rem;
    --h4-font-size: 1.6rem;
    --h5-font-size: 1.2rem;
    --h6-font-size: 1.1rem;
    --theme-border-radius: 0;
    --theme-border-radius-button: 100px;
    --header-font-weight: 600;
    --header-margin-top: 2.4rem;
    --header-margin-bottom: 1.2rem;
    --header-hero-font-size: 8rem;
    --header-line-height: 1.2;
    --narrow-content-width: 1000px;
    --body-line-height: 1.8;
    --heading-letter-spacing: .08rem;
    --wishlist-heart-hover-color: var(--picment-color-3-2);
    --grey-background-color: #f5f5f5;
    --white-background-color: #fff;
    --soft-black: #181818;
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
    color: var(--body-content-color);
    font-weight: var(--p-font-weight);
    line-height: var(--body-line-height);
    background: var(--white-background-color);
    font-family: var(--font-family);
}

p {
    font-size: var(--p-font-size);
    margin-bottom: 1.2rem;
    line-height: var(--body-line-height);
}

li,
dl {
    font-size: var(--p-font-size);
}

h1, .h1 {
    font-size: var(--h1-font-size);
    font-weight: 600 !important;
}

h2, .h2 {
    font-size: var(--h2-font-size);
}

h3, .h3 {
    font-size: var(--h3-font-size);
}

h4, .h4 {
    font-size: var(--h4-font-size);
}

h5, .h5 {
    font-size: var(--h5-font-size);
}

h6, .h6 {
    font-size: var(--h6-font-size);
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 + h2,
h1 + h3,
h1 + h4,
h2 + h3,
h2 + h4,
h3 + h4 {
    font-weight: var(--header-font-weight);
    line-height: var(--header-line-height);
    color: var(--body-heading-color);
    top: 0;
    margin-top: 0;
    margin-bottom: var(--header-margin-bottom);
    text-transform: none;

    /* text-transform: uppercase; */
    letter-spacing: var(--heading-letter-spacing);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &:not(:first-child) { margin-top: var(--header-margin-top); }
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: var(--header-font-weight);
    line-height: var(--header-line-height);
    color: var(--body-heading-color);
    letter-spacing: var(--heading-letter-spacing);
}

main,
main.ProductPage {
    @include mobile {
        /* margin-top: calc(var(--header-total-height) + var(--header-sub-height)); */
        margin-top: var(--header-total-height);

        @at-root .isHomePage & {
            margin-top: var(--header-total-height);
        }
    }
}

// strong { 
//     color: var(--body-heading-color); 
// }

input,
select {
    font-size: 1.4rem;
}

input {
    &[type='number'] {
        font-weight: 500;
    }
}

body .cms-spacer.triple-spacer-height {
    @include mobile {
        height: var(--cms-vertical-padding-x1);
    }
}

@include mobile {
    body .Button {
        font-size: 1.2rem;
    }
}

.Image.cms-banner-background {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    top: 0;
    left: 0;

    .Image-Image {
        object-fit: cover;
    }
}

.cms-module-categorybanner-link {
    opacity: 0;
}

.MyAccountMyWishlist {
    .Field {
        #item_qty {
            &[type='number'] {
                border: 1px solid var(--cms-button-primary-border-color) !important;

                ~ button:first-of-type,
                ~ button:last-of-type {
                    height: unset;
                    width: 20px;
                }

                ~ button:first-of-type {
                    border-left-width: 0;
                }

                ~ button:last-of-type {
                    border-right-width: 0;
                }
            }
        }
    }
}
