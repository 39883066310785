/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.AddSampleToCart {
    min-width: 14.4rem;
    vertical-align: middle;

    @include mobile {
        min-width: 16.8rem;
    }

    span {
        display: block;
        opacity: 1;
        transform: translateY(0);
        transition-property: transform, opacity;
        will-change: transform, opacity;
        transition-timing-function: ease-in;
        transition-duration: .25s;

        &:last-child {
            width: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            transform: translateY(0);
        }
    }

    &_isPlaceholder {
        height: 4.8rem;
        display: inline-block;
        padding: var(--button-padding);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        will-change: background-position;

        @include mobile {
            height: 5.6rem;
        }
    }

    &_isLoading {
        span {
            &:first-child {
                opacity: 0;
                transform: translateY(-50%);
            }

            &:last-child {
                opacity: 1;
                transform: translateY(-155%);
            }
        }

        &:active {
            span {
                &:last-child {
                    opacity: .9;
                    transform: translateY(-120%);
                }
            }
        }
    }
}