/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Breadcrumbs {
    --breadcrumbs-divider-color: #fff;

    background-color: transparent;
    // z-index: 2;
    top: var(--header-total-height);

    border: none !important;
    
    
    &-List {       
        margin: 0 auto;
    }

    @include mobile {
        display: flex;
    }


    @include desktop {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    


    .ContentWrapper {
        max-width: calc(var(--content-wrapper-width) + 100px);
        padding-left: 1.4rem;
        padding-right: 1.4rem;
        width: 100%;

        @media (min-width: 1025px) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    @at-root .isProductPage & {
        background-color: var(--breadcrumbs-background);
        border-bottom: var(--product-page-border);
    }

    @at-root .isBlogOverview & {
        background-color: var(--breadcrumbs-background);
        z-index: 2;
        display: none;
    }

    @at-root .isCmsPage & {
        background-color: var(--breadcrumbs-background);
        z-index: 2;
        display: none;
    }

    @at-root .isCategoryPageCmsOnly & {
        background-color: var(--breadcrumbs-background);
        z-index: 2;
        display: none;
    }

    @at-root .Header-Wrapper_name_posts-details + & {
        background-color: var(--breadcrumbs-background);
    }

    @at-root .isCategoryPage & {
        background-color: var(--picment-color-4-1);
    }
}

.Header-Wrapper_name_posts-details + .Breadcrumbs {
    background: transparent;

    display: none;

    .Breadcrumb {
        &-Link {
            color: #fff;
            opacity: .7;
        }

        &::after {
            border-color: rgba(255, 255, 255, .7);
        }

        &:last-child {
            .Breadcrumb-Link {
                opacity: 1;
                font-weight: 500;
            }
        }
    }
}
