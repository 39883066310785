@import 'cms-variables';
@import 'cms-modules';
@import 'cms-button';
@import 'pages/cms-store-loactions';
@import 'pages/cms-inspiration';
@import 'cms-image-ratios';

:root {
  --content-v-padding: 100px;
}

.cms-section {
  > .cms-section-container {
    padding-right: 0;
    padding-left: 0;
    padding-top: none;
    width: 100%;

    .ContentWrapper {
      width: 100%;
    }

    @include phone {
      width: calc(100% - 4.8rem);
    }

    &.cms-section-container-wide {
      max-width: 100%;
      @include desktop {
        padding-left: var(--content-v-padding);
        padding-right: var(--content-v-padding);
      }
    }
  }
}

.cms-button {
  text-align: center;
}

.CmsPage-Body {
  li a {text-decoration: none;}

  a {text-decoration: underline;}
  
  &_isNewProducts a {text-decoration: none;}
  
  .Image{mix-blend-mode: multiply;}

  .Image_noBlend {mix-blend-mode: unset;}
}


.grid-half.omega, .grid-half.alpha{
  float: left;
  width: calc(100% / 2);
}

.grid_3.alpha{
  width: calc(100% / 3);
  float: left;
  padding-right: 25px;
}

.grid_6.omega{
  float: left;
  width: calc((100% / 3) * 2);
}

.grid_2.alpha{
  width: 100%;
  mix-blend-mode: multiply;
}

hr{
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.clear{
  content: "";
  display: block;
  clear: both;
}

.videoWrapper{
  margin-left: 0;
  width: 100%;
}

ul.logo-list{
  display: flex;
    flex-wrap: wrap;
  li{
    padding-left: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #eee;
    @include desktop{
      width: 33%;
      &:nth-child(3n+2){
        border-right: 1px solid #eee;
        border-left: 1px solid #eee;
      }
    }
    @include mobile{
      width: 50%;
      &:nth-child(1n+1){
        border-right: 1px solid #eee;
      }
    }
    &::before{
      content: "";
    }
  }
}
.footer-icons{
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: auto;
  flex-wrap: wrap;
  a{
    @include desktop{
      width: 60px;
      height: 60px;
    }
    @include mobile{
      width: 45px;
      height: 45px;
    }
  }
}

.customerservice-box{
  text-align: left;
  margin-bottom: 3em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  > div{
    text-align: center;
    width: 50%;
  }
  > div.clear{
    width: 0;
  }
  > h2{
    width: 100%;
  }
}
#emaerket{
  float: left;
  width: 100%;
}
#butikker{
  float: left;
    width: 100%;
}