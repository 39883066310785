/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
.Clerk {
    &-Wrapper {
        padding: 0;
    }

    &-Title {
        letter-spacing: var(--heading-letter-spacing);
        font-size: var(--h3-font-size);
        font-weight: var(--header-font-weight);
        border: none;
        height: 50px;
        line-height: 50px;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        text-align: center;
        text-transform: none;

        @include mobile {
            font-size: 1.2rem;
            padding-top: 2px;
        }
    }

    &-Slider {
        margin-top: 0;

        .slick-slider {
            .slick-arrow {
                top: -45px;

                &::before {
                    font-size: 0;
                    display: block;
                    height: 30px;
                    width: 30px;
                }

                &.slick-prev {
                    left: 0;

                    &::before {
                        background-image: url(icons/chevron-left.svg);
                    }
                }

                &.slick-next {
                    right: 0;

                    &::before {
                        background-image: url(icons/chevron-right.svg);
                    }
                }
            }

            .slick-list {
                margin: 0 -5px;
            }

            .slick-slide {
                padding: 0 5px;
            }

            .ProductCard {
                padding: 0;
            }
        }
    }
}
