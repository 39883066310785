:root {
    --max-category-content-width: 1000px;
}

.CategoryPage-Wrapper_hideProducts {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    max-width: 100% !important;

    .CategoryPage-LongText {
        width: calc(100% - 100px);
        margin: auto auto 70px;
        max-width: var(--max-category-content-width);
    }

    .CategoryPage-CMS {
        padding: 0;
    }

    .cms-col.cms-col-3 {
        width: 33.33%;
        @include mobile {
            width: 100%;
        }
    }

    .cms-col.cms-col-3-4 {
        width: 25%;
        @include mobile {
            width: 100%;
        }
    }

    .cms-txt-banner {
        background: var(--picment-color-4-1);
        overflow: hidden;

        @include mobile {
            #no-mob {display: none;}
        }

        &-inner {
            display: grid;
            grid-template-columns: repeat(8, 1fr);

            @include mobile {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &-col {
            padding: 2rem 2rem 0;
            text-align: center;

            h2 {
                color: var(--picment-color-2-1);
                font-size: 1.3rem;
                margin-bottom: 0;
            }

            p { font-size: 1.1rem; }
        }
    }
}

.cms-category-text {
    max-width: var(--max-category-content-width);
    margin: 0 auto;
    @include mobile {
        padding: 0 1rem;
    }
}

.videoWrapper {
    position: relative;
    overflow: hidden;
    @include desktop {
        margin-left: -100px;
        width: calc(100% + 200px);
    }
    @include mobile {
        width: 100%;
    }

    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
