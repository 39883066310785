/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
body .ColorSwatchPicker {

    &-Popup {
        background-color: rgba(0, 0, 0, .5);
    }
    &-ExpandableContentContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &-ColorCodeContainer {
        display: flex;
        flex-direction: revert;
        align-items: center;
        justify-content: flex-start;
        margin-top: 0;
        flex: 1;
        padding-left: 5px;

        @include phone {
            margin-top: 0.6rem;
            width: 100%;
            padding: 0;
        }

        .Field {
            margin-top: 0;
            flex: 1;

            input {
                // border: none;
                // padding: 0;
                // font-size: var(--body-font-size);
                // padding-left: 5px;
                // background-color: transparent !important;
                // cursor: text;

                width: auto;
                background-color: #fff;
                width: 100%;
                border-radius: 100px;
                height: 48px;

                @include desktop {
                    width: 130%;
                }

                @include phone {
                    text-align: center;
                }
            }
        }
    }

    &-ColorCodeLabel {
        font-weight: 700;
        display: none;
    }

    &-PopupContent {
        background: var(--grey-background-color);
        width: 100%;
        height: 100%;
    }

    &-CollectionButtonContainer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1.2rem;

        @include mobile {
            grid-template-columns: repeat(2, 1fr);
        }

        @include phone {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &-CollectionButton {
        padding: 12px 16px;
        font-size: 1.1rem;
        background: transparent;
        color: var(--body-heading-color);
        font-weight: 500;
        border-color: rgba(0, 0, 0, .3);

        &_isActive,
        &_isActive:focus,
        &_isActive:active,
        &_isActive:not([disabled]):hover,
        &_isActive:not([disabled]):focus {
            background: #000;
            color: #fff;
        }
    }

    &-ColorList {
        grid-template-columns: repeat(6, 1fr);
        margin-top: 2.4rem;
        grid-gap: 1.2rem;

        @include mobile {
            grid-template-columns: repeat(4, 1fr);
        }

        @include phone {
            grid-template-columns: repeat(2, 1fr);
        }

        &_isActive {
            display: grid;
        }
    }

    &-Color {
        background: #fff;
        padding: 1.2rem;
        cursor: pointer;
    }

    &-ColorArea {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    &-ColorText {
        margin: .6rem 0 0 0;
        text-align: center;
    }
}
