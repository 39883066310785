/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Notification {
    --notification-color: #0a0a0a;
    background: #fff;
    padding: 1.2rem 1.2rem 1.2rem 2.4rem;
    border-left: 5px solid var(--notification-background);
    box-shadow: 2px 0 5px 0 rgba(0, 0, 0, .1);
    margin-bottom: 0;

    &:not(:first-child) {
        margin-top: .6rem;
    }

    &-Text {
        font-weight: 500;
    }

    &-Button {
        padding: 2.2rem;
    }
}
