/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
:root {
    --bottom-lift: 6vh;
}

.DepartmentMenu {
    &-Title {
        display: none;
    }

    &-Icon {
        background-image: url('./icons/burger.svg');
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-size: 40px 40px;
        width: 30px;
        height: 30px;
    }

    &-MenuList {
        display: flex;
        flex-flow: column wrap;
        max-width: var(--content-wrapper-width);
        width: 75%;
        //padding-left: 1.2rem;
        //padding-right: 1.2rem;
        margin-left: auto;
        margin-right: auto;
        font-family: var(--display-font);
        font-size: 6rem;
    }

    &-MenuItem {
        line-height: 1.4;
    }

    &-MenuItemLink {
        color: var(--picment-color-4-1);
        text-decoration: none !important;
        transition: all ease .2s;
        font-size: 7rem;

        &:hover {
            color: var(--picment-color-3-2);
        }
    }

    &-BottomMenuContainer {
        position: absolute;
        left: 50%;
        bottom: var(--bottom-lift);
        transform: translateX(-50%);
        max-width: var(--content-wrapper-width);
        width: 75%;
        //padding-left: 1.2rem;
        //padding-right: 1.2rem;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }

    &-BottomMenu {
        .BottomMenu-icons{
            display: flex;
            justify-content: space-around;
            margin: auto;
            flex-wrap: wrap;
            a{
              @include desktop{
                width: 40px;
                height: 40px;
                border: 1px solid var(--picment-color-1-3);
                border-radius: 100%;
                padding: 8px;
                margin: 10px;
              }
              @include mobile{
                width: 45px;
                height: 45px;
              }
            }
          }
        ul {
            list-style: none;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            li {
                padding: 0;
                margin: 0;
                display: flex;

                &::before {
                    content: unset;
                    display: none;
                }
            }

            li,
            a,
            li a {
                color: var(--picment-color-1-3);
            }
        }

        &_type {
            &_left {
                ul {
                    li {
                        margin-right: 3.6rem;

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }

            &_center {
                ul {
                    li {
                        margin-left: .6rem;
                        margin-right: .6rem;
                        font-size: 6rem;
                        line-height: 1rem;
                    }
                }
            }

            &_right {
                ul {
                    li {
                        margin-left: 3.6rem;

                        &:first-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    &Popup {
        .Popup {
            &-Content {
                width: 100%;
                max-width: 100%;
                height: 100%;
                max-height: 100%;
                border-radius: 0;
                overflow: hidden;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: var(--picment-color-3-1);
                padding-bottom: var(--bottom-lift);
            }

            &-Header {
                position: absolute;
                top: 0;
                left: 0;
            }

            &-Heading {
                display: none;
            }

            &-CloseBtn {
                position: absolute;
                top: calc((var(--header-nav-height) / 2) + 34px);
                left: var(--header-v-padding);
                transform: translateY(-50%);
                width: 30px;
                height: 40px;
                //border: 1px solid var(--picment-color-3-2);
                //border-radius: 10% 100% 100% 100%;

                &::before,
                &::after {
                    background-color: var(--picment-color-3-2);
                    width: 2px;
                    height: 24px;
                }
            }
        }
    }
}
