/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Popup {
    @include mobile {
        &_isVisible {
            height: 100vh;
        }

        &-Header {
            display: flex;
            margin-bottom: 1.2rem;

            @at-root .ImageZoomPopup & {
                margin: 0;
            }
        }

        &-CloseBtn {
            top: 0 !important;
        }

        &-Content {
            .ProductReviewForm {
                padding-bottom: 50px;
            }
        }
    }

    &.CheckoutTermsAndConditionsPopup {
        .Popup-Content {
            --h1-font-size: 2.6rem;
            --h2-font-size: 2.4rem;
            --h3-font-size: 2rem;
            --h4-font-size: 1.6rem;
            --h5-font-size: 1.2rem;
            --h6-font-size: 1rem;
        }
    }
}
