/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.StoreSwitcher {
    margin-top: 2.4rem;
    border: 1px solid var(--primary-divider-color);
    padding: 0 1.2rem;
    position: absolute;
    bottom: 0;
    width: 100%;

    select {
        padding-left: 0;
        padding-right: 0;
        font-size: 1.2rem;
        color: var(--body-content-color);
    }

    .FieldSelect {
        &::after {
            right: 0;
        }
    }
}
