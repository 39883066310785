.cms-module-usps {
    // .cms-section-container {
    //     background-color: var(--theme-color-2);
    //     padding: 2.4rem 0;
    // }

    @include phone {
        margin-bottom: -2.4rem;
    }

    .cms-usps-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 4rem;

        @include phone {
            flex-direction: column;
            margin-bottom: 2.4rem;
            padding: 0;
        }

        .Image {
            width: 200px;
            padding-bottom: 200px;

            @include phone {
                width: 200px;
                padding-bottom: 200px;
                margin-bottom: -4rem;
            }
        }

        .cms-usps-item-content {
            > *:last-child {
                margin-bottom: 0;
            }

            p {
                font-size: var(--h4-font-size);
                color: var(--body-heading-color);

                @include phone {
                    font-size: var(--h5-font-size);
                }
            }
        }
    }

    &.cms-module-usps_with-desc {

        .Image {
            margin: -2rem 0 -5rem;
        }

        .cms-usps-item {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            .cms-usps-item-content {

                p {
                    font-size: var(--body-font-size);
                }
            }
        }
    }
}
