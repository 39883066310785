.CmsPage {
  &-Top {
    background-position: bottom !important;
    padding-bottom: 0px !important;
  }
  .store-address {
   width:100%;
   float:left;
   border-right: none;
  }
  .store-open-hours{
   width:50%;
   float:left;
   text-overflow: ellipsis;
   white-space: nonwrap;
   overflow: hidden;
  }
  .txt-right {
   text-align:right;
  }
  .pad1em {
   padding: 0em;
  }
  .btn-maps {
   border: 1px solid black;
   text-align: center;
   display: flow-root;
   padding: 1em;
  }
  #deskOnly {display:none;}
  
  @include desktop {
    .store-address {
      width:40%;
      float:left;
      border-right: 1px solid lightgrey;
     }
     .store-open-hours{
      width:30%;
     }
     .pad1em {
      padding: 1em;
     }
     #deskOnly {display:block;}
  }

  .Table-Wrapper {
    overflow-x: unset;
  }
  tbody {
    tr,
    th,
    td {
      background: none;
      padding: 0;
    }
  }
  .showroom-address-column {
    display: grid;
    @include desktop {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0 80px;
    }
    @include mobile {
      grid-template-columns: 1fr 1fr;
      grid-gap: 80px;
      @media only screen and (max-width: 450px) {
        grid-template-columns: 1fr;
        grid-gap: 40px 0;
      }
    }

    .showroom-column {
      display: grid;
      grid-template-columns: 1fr;
    }
    .button {
      padding: 0.5em 1em;
      border: 1px solid $black;
      transition: 0.2s ease-in-out;
      &:hover {
        background: $black;
        color: $white;
        text-decoration: none;
      }
    }
  }
  .img-collage-sq-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 0;
    background-color: var(--picment-color-4-1);
    margin: 4rem 0;

    .Image {
      mix-blend-mode: normal;
    }
    @include desktop {
      padding: 4rem;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
