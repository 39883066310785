.inspiration-chooser{
    display: grid;
    grid-gap: 1.8rem 3.9rem;
    @include desktop{
      grid-template-columns: repeat(3,1fr);
    }
    @include mobile{
      grid-template-columns: repeat(2,1fr);
    }
  li{
    padding: 1.8rem 3.9rem;
    text-align: center;
    &::before{
      content: "";
    }
  }
}


