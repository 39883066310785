/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../style/abstract/variables';
 @import '../../../../style/abstract/media';
 @import '../../../../style/abstract/button';
 @import '../../../../style/abstract/loader';
 @import '../../../../style/abstract/icons';
 @import '../../../../style/abstract/parts';
 @import '../../../../style/abstract/theme-abstract';
 
.ClerkSearch {

    --header-height-w-search: 114px;

    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    @include desktop {
        position: relative;
        top: 0;
        transform: none;
        left: 0;
    }

    &-InputWrapper {
        width: 100%;

        @include mobile {
            width: 100%;
        }
    }

    &-Input {
        @include desktop {
 
            @at-root .isHomePage & {
                caret-color: white;
                color: #fff;
            }
            @at-root .isHomePage &-Icon {
                filter: invert(1);
            }

            @at-root .isBlogOverview & {
                caret-color: white;
                color: #fff;
            }
            
            @at-root .isBlogOverview &-Icon {
                filter: invert(1);
            }

            @at-root .Header-Wrapper_name_posts-details & {
                caret-color: white;
                color: #fff;
            }
            
            @at-root .Header-Wrapper_name_posts-details &-Icon {
                filter: invert(1);
            }

            @at-root .isCmsPage & {
                caret-color: white;
                color: #fff;
            }

            @at-root .isCmsPage &-Icon {
                filter: invert(1);
            }
            
            @at-root .isCategoryPageCmsOnly & {
                caret-color: white;
                color: #fff;
            }

            @at-root .isCategoryPageCmsOnly &-Icon {
                filter: invert(1);
            }
        }
        
        background: transparent;

        @include desktop {
            min-width: 0;
        }

        &-Icon {
            opacity: 0.7;
            pointer-events: none;
            width: 26px;
            height: 26px;
        }

        /* @include mobile {
            &:focus {
                border-bottom: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        } */
    }

    &-Results {
        top: calc(100% - 10px);
        transform: translate(-50%, 0);
        left: 50%;
        display: grid;
        grid-template-columns: 12fr 8fr;
        width: 90vw;
        max-width: 1000px;
        border-radius: 5px;

        @include desktop {
            left: 0;
            transform: translateX(0);
            top: calc(100% + 1.2rem);
        }

        @include mobile {
            top: calc(100% + 10px);
            width: 100%;
            max-width: 100%;
        }

        @include phone {
            top: 100%;
            grid-template-columns: 1fr;
            width: 100vw;
            max-width: 100vw;
            border-radius: 0;
            box-shadow: none;

            /* Fix for scrolling on mobile */
            height: calc(100vh - var(--header-height-w-search));
            overflow: scroll;
        }       

        .SearchItem {
            margin: 0;
            border-bottom: 1px dotted rgba(0, 0, 0, .1);

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border: none;
                padding-bottom: 0;
            }
        }
    }

    &-ResultHeading {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.4rem;
    }

    &-ResultColumn {
        padding: 2.4rem;

        &_type {
            &_leftColumn {
                border-bottom: 1px solid rgba(0, 0, 0, .05);
                border-right: 1px solid rgba(0, 0, 0, .05);

                @include phone {
                    border-right: none;
                }
            }

            &_rightColumn {
                border-bottom: 1px solid rgba(0, 0, 0, .05);
                padding: 0;
            }

            &_bottom {
                grid-column: 1 / 3;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                @include phone {
                    grid-column: 1 / 2;
                }
            }
        }
    }

    &-ResultContainer {
        &_type {
            &_suggestions {
                padding: 2.4rem;
                border-bottom: 1px solid rgba(0, 0, 0, .05);
            }

            &_categories {
                padding: 2.4rem;
            }
        }
    }

    &-SuggestionItem,
    &-CategoryItem {
        padding: 0;

        &::before {
            display: none;
        }
    }

    &-SuggestionItem {
        &:last-child {
            margin-bottom: 0;
        }
    }

    @at-root .Header-Nav & {
        @include mobile {
            display: none;
        }
    }

    @at-root .isCheckout & {
        display: none;
    }

    .SearchItem-Wrapper {
        .Image {
            img {
                object-fit: cover;
            }
        }
    }
}

.CategoryProductList {
    &-Page {
        @at-root .isSearchPage & {
            grid-template-columns: repeat(5, 1fr);

            @include mobile {
                grid-template-columns: repeat(3, 1fr);
            }

            @include phone {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

.picment_dk_store_view.isHomePage .ClerkSearch-Input,
.picment_dk_store_view.isBlogOverview .ClerkSearch-Input,
.picment_dk_store_view.Header-Wrapper_name_posts-details .ClerkSearch-Input,
.picment_dk_store_view.isCmsPage .ClerkSearch-Input,
.picment_dk_store_view.isCategoryPageCmsOnly .ClerkSearch-Input {

    @include desktop {
        color: var(--picment-color-3-1);
        caret-color: var(--picment-color-3-1);
        padding: 0;
    }
}

.picment_dk_store_view.isHomePage .ClerkSearchWrapper-Search_isActive .ClerkSearch-Input,
.picment_dk_store_view.isBlogOverview .ClerkSearchWrapper-Search_isActive .ClerkSearch-Input,
.picment_dk_store_view.Header-Wrapper_name_posts-details .ClerkSearchWrapper-Search_isActive .ClerkSearch-Input,
.picment_dk_store_view.isCmsPage .ClerkSearchWrapper-Search_isActive .ClerkSearch-Input,
.picment_dk_store_view.isCategoryPageCmsOnly .ClerkSearchWrapper-Search_isActive .ClerkSearch-Input {
    padding: 1rem 5.5rem 1rem 3rem;
}
.picment_dk_store_view .ClerkSearch-Results {
    
    @media (min-width: 768px) and (max-width: 1499px) {
        width: 100%;
        grid-template-columns: 1fr;
    }
    @media (min-width: 1499px) {
        width: 36vw;
        grid-template-columns: 12fr 8fr;
    }
}

.picment_dk_store_view .ClerkSearch-ResultColumn_type_bottom {
    @media (min-width: 769px) and (max-width: 1499px) {
        grid-column: 1 / 2;
    }
}

.picment_dk_store_view .ClerkSearchWrapper-Button {
    margin-bottom: 1rem;

    @include mobile {
        &:first-child {
            &::after {
                content: "Søg";
                position: absolute;
                bottom: -1.54em;
                font-size: 10px;
                text-transform: capitalize;
                color: #000;
                padding-left: 0.4em;
            }
        }
    }
}