.Image {
  &_ratio {
    &_3x2 {
      padding-bottom: 66.66% !important;
    }
    &_2x3 {
      padding-bottom: 133.33% !important;
    }
    &_3x4 {
      padding-bottom: 125% !important;
    }
    &_5x2 {
      padding-bottom: 40% !important;
    }
    &_9x16 {
      padding-bottom: 150% !important;
    }
    &_16x10 {
      padding-bottom: 62.5% !important;
    }
    &_10x16 {
      padding-bottom: 137.5% !important;
    }
    &_1-85x1 {
      padding-bottom: 54.05% !important;
    }
    &_2-39x1 {
      padding-bottom: 41.84% !important;
    }
    &_4x1 {
      padding-bottom: 25% !important;
    }
  }
}