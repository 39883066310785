/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
@mixin menu-overlay {
    position: fixed;
    top: var(--header-total-height);
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--picment-color-3-1);
    height: auto;
    z-index: 1;
}

.MenuOverlay {
    /***** Styles need to be "strongly incentivised" to apply *****/
    @include menu-overlay;
    @include mobile {
        @include menu-overlay;
    }

    &.Overlay {
        @include menu-overlay;
        @include mobile {
            @include menu-overlay;
        }
    }

    /* ******** */

    &-Results {
        background: transparent;
        min-height: 100%;

        @include desktop {
            padding: 10rem 0 10rem 20vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    .StoreSwitcher {
        display: none;
    }
}

/* DONE: White DK background */
.picment_dk_store_view .MenuOverlay {
    background: #fff;
}