/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */



 @font-face {
  font-family: 'Blacker Sans'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 500; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('Blacker Sans Display Extrabold'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/Blacker-Sans-Display-Extrabold.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/Blacker-Sans-Display-Extrabold.woff') format('woff'), /* Anden mest optimerede fonttype til nyere browsere (3. prioritet) */
      url('/style/fonts/Blacker-Sans-Display-Extrabold.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */     
      url('/style/fonts/Blacker-Sans-Display-Extrabold.ttf') format('truetype'); /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}

@font-face {
  font-family: 'WindSong'; /* Navn på fonten */
  font-style: normal; /* Normal eller italic */
  font-weight: 100; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
  font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
  src: local('WindSong Regular'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
      url('/style/fonts/WindSong-Regular.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
      url('/style/fonts/WindSong-Regular.woff') format('woff'), /* Anden mest optimerede fonttype til nyere browsere (3. prioritet) */
      url('/style/fonts/WindSong-Regular.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
      url('/style/fonts/WindSong-Regular.ttf') format('truetype'); /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
}
 
:root {
    --input-type-number-width: 80px;
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderanimation 2s linear infinite;
    --primary-divider-color: #f0f0f0;
    --secondary-error-color: #fff;
    --primary-error-color: #dc6d6d;
    --secondary-success-color: #fff;
    --primary-success-color: #7fcd91;
    --secondary-info-color: #79632e;
    --primary-info-color: #ffd166;

    // Extra theme fonts
    --display-font: 'Blacker Sans';

    // Theme text color
    --body-heading-color: #333;
    --body-heading-color-light: #eaeaea;
    --body-content-color: #5e5e5e;
    --body-content-color-light: #f0f0f0;

    // Picment colors
    --picment-color-1-1: #0424BD;
    --picment-color-1-2: #E0F2FF;
    --picment-color-1-3: #52C976;
    --picment-color-2-1: #5B001A;
    --picment-color-2-2: #F2D6C6;
    --picment-color-2-3: #E2FFED;
    --picment-color-3-1: #102D25;
    --picment-color-3-2: #E53E78;
    --picment-color-3-3: #555634;
    --picment-color-4-1: #F7F5EF;
    --picment-color-4-2: #994904;
    --picment-color-4-3: #AA7979;
    --picment-portland-stone: #e3dfce;
    --picment-forest-green: forestgreen; 
    --picment-color-check-bullet: #dbdbdb;
    --fixedlowprice: #52C976;

    // Theme specific colors
    --grey-background-main: #{$default-secondary-base-color};
    --grey-background-light: #f9f9f9;
    --grey-background-dark: #{$default-secondary-dark-color};
    --theme-dark-blue: #0f223d;
    //--popup-background: rgba(0, 0, 0, .5);
    --popup-background: #F7F5EF;
    --white: $white;
    --black: $black;
    --theme-border-color: rgba(0, 0, 0, .07);
    --theme-color-1: var(--picment-color-1-1);
    --theme-color-2: var(--picment-color-1-2);
    --theme-color-3: var(--picment-color-3-2);
    --theme-color-4: var(--picment-color-1-2);
    --theme-color-5: var(--picment-color-3-1);
    --theme-color-6: var( --picment-color-4-2);
    --blog-categories-border: 1px solid rgba(0, 0, 0, .07);

    // Declare imported primary colors to change the theme's colors
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--grey-background-color);
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
}
