/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.ProductWishlistButton {
    margin: 0;
    @include desktop {
        height: 0;
    }
    &-Button {
        &.Button {
            border: none;
            background: transparent !important;
        }

        &_isInWishlist {
            --wishlist-heart-background: var(--picment-color-3-2);
        }
    }

    &-Heart {
        transition: all ease .2s;
        --wishlist-heart-size: 16px;

        &::before,
        &::after {
            transition: all ease .2s;
        }

        @at-root .ProductWishlistButton:hover & {
            background-color: var(--wishlist-heart-hover-color) !important;

            &::after,
            &::before {
                background-color: var(--wishlist-heart-hover-color) !important;
            }
        }
    }
}

main:not(.ProductPage) {
    @include desktop {
        .ProductWishlistButton {
            opacity: 0;
            visibility: hidden;
            // transition: all ease .3s; ** This will cause iPhones to need to double tap one a product to access it
        }

        .ProductCard:hover {
            .ProductWishlistButton {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
