/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.ProductAttributeValue {

    
    &-Text {
        label,
        .input-control {
            font-weight: 400;
            cursor: pointer;
            margin: 0;
            color: var(--body-content-color);
            text-transform: none;
        }
    }

    &-TextArea {
        @at-root .isCategoryPage .ProductAttributeValue_isSelected & {
            font-weight: 600;
            padding-left: 1.2rem;

            &::before {
                content: '✕';
                position: absolute;
                left: 0;
                font-size: 1rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &-Color,
    &-Image-Overlay {
        border-radius: 10%;
    }

    &-Color {
        --option-border-color: rgb(41, 41, 41) !important;
        --option-check-mark-background: rgb(41, 41, 41) !important;
    }

    &-Image {
        border-radius: 10%;
        width: var(--option-size);
        height: var(--option-size);
    }

    &_isNotAvailable {
        @at-root .ProductConfigurableAttributes & {
            // display: none;
        }
    }

    &-Text,
    &-String {
        &_isSelected {
            @at-root .isProductPage & {
                background: var(--primary-base-color);
                color: #fff;
            }
        }
    }

    &-Count {
        float: right;
    }

    @at-root .ProductPage & {
        @include mobile {
            --option-size: 36px;

            &-String {
                font-size: 1.2rem;
            }
        }
    }

    @at-root .CategoryFilterOverlay & {
        border-top: 1px solid #efefef;
        display: block;
        padding: 0.5em 1em;
        margin-left: -1em;
        margin-right: -1em;
        text-align: left;
    
        &:hover {
            background: #f9f9f9;
        }
    }
}