/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --loader-scale: 1.2;
}

.Loader {
    background-color: rgba(255, 255, 255, .6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 75;
    margin: auto;
    opacity: 0;
    animation: appear 1ms 200ms forwards;

    &-Main {
        span {
            display: block;
            height: 60px;
            width: 60px;
            background-image: url(icons/roller-filled.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            animation: MoveUpDown 2s linear infinite;
        }
    }

    &-Scale {
        position: absolute;
        transform: translate(-50%, -50%) scale(var(--loader-scale));
        left: 50%;
        top: calc(50% - (3px * var(--loader-scale)));
    }
}

@keyframes MoveUpDown {
    0% {
        top: 0;
    }

    25% {
        top: 25px;
    }

    50% {
        top: 0;
    }

    75% {
        top: -25px;
    }

    100% {
        top: 0;
    }
}
