/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
:root {
    --searchinput-width: 400px;
    --DK-searchinput-width: 360px;

    @media screen and (min-width: 1024px) and (max-width: 1450px) {
        --searchinput-width: 300px;
        --DK-searchinput-width: 300px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1250px) {
        --searchinput-width: 200px;
        --DK-searchinput-width: 240px;
    }
}

.ClerkSearchWrapper {
    &-Button {
        width: 26px;
        height: 26px;
        top: 50%;
        background-image: url('/../../hoeks-base/src/component/Header/icons/icon-search.svg');
        background-size: contain;
        background-position: center;
        font-size: 0;
        cursor: pointer;
        opacity: 1;
        pointer-events: all;
        left: 0;
        opacity: 0.7;
        
        @include desktop {
            position: absolute;
            transform: translateY(-50%);
            transition: all ease .5s;            
        }

        
        .default_view_se.isHomePage &,
        .default_view_se.isBlogOverview &,
        .default_view_se.Header-Wrapper_name_posts-details &,
        .default_view_se.isCmsPage &,
        .default_view_se.isCategoryPageCmsOnly & {
            @include desktop {
                filter: invert(1);
                color: #fff;
            }
        }
        /* End of comment */

        &_isActive {
            @include desktop {
                // opacity: 0;
                pointer-events: none;
                left: calc(var(--searchinput-width) - 4.6rem);
            }

            @include mobile {
                background-size: 40px 40px;
                background-image: url('icons/close.svg');
            }
        }
    }

    &-Search {
        width: 0;
        // overflow: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all ease .5s;

        @include mobile {
            position: fixed;
            top: 50px;
            left: 0;
            right: 0;
            width: 100vw;
            padding: 1.2rem;
            background: #fff;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
            top: 59px;
        }

        &_isActive {
            opacity: 1;
            pointer-events: all;
            
            @include desktop {
                width: var(--searchinput-width);
            }
            @include mobile {
                z-index: 21;
                box-shadow: 0 4px 4px rgba(0,0,0,.1);
            }
        }
    }
}

.picment_dk_store_view {

    // &.isCmsPage .ClerkSearch-Input-Icon {
        .ClerkSearch-Input-Icon {
        filter: invert(0);
        display: none;
    }
    // .ClerkSearchWrapper {
    //     @include desktop {
    //         &-Button {
    //             left: -25px;

    //             &_isActive {
    //                 display: none;
    //             }
    //         }
    //     }
    // }
}

@include desktop {
    .picment_dk_store_view .ClerkSearchWrapper-Search_isActive {
        width: var(--DK-searchinput-width);
    }
    .picment_dk_store_view .ClerkSearchWrapper-Button_isActive {
        left: calc(var(--DK-searchinput-width) - 4.6rem)
    }
}