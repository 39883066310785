.cms-module-categorybanner {
    overflow: hidden;
    z-index: 0;

    .cr-container {
        padding: 4rem 4rem 0; 
        max-width: 800px; 
        margin: auto;
        @include desktop {
            padding: 8rem 4rem;
        }
    }
    .cr-container blockquote {
        line-height: 3rem;

        @include desktop {
            line-height: 5rem;
        }
    }
    .cr-container blockquote:before { 
        content:"❝";
        font-size:20rem;
        position:absolute;
        top:25px;
        left:-100px; 
        color: var(--picment-color-4-1); 
        z-index:-1;
    }
    .cr-head{ 
        font-size: 1.2rem; 
        text-align: center;
    }
    .cr-statement{ 
        font-size: 3rem;
        font-style: italic;
        @include desktop {
            font-size: 5rem;
        }
    }
    .cr-txt{ 
        font-size: 1.5rem; 
        line-height:2rem;
        padding: 0 1.5rem;
    }

    &-grid {
        display: grid !important;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        grid-template-rows: repeat(4, 40vh);
        grid-template-columns: 1fr;

        @include desktop {
            grid-template-rows: repeat(2, 40vh);
            grid-template-columns: repeat(4, 1fr);
        }

        @include tablet {
            grid-template-rows: repeat(2, 40vh);
            grid-template-columns: repeat(2, 1fr);
        }
        #nohide {
            overflow: auto;
        }
        #reverse {
            @include desktop {
                &:nth-child(1) {
                    grid-column: 1/3;
                    grid-row: 1/2;
                }
    
                &:nth-child(2) {
                    grid-column: 1/2;
                    grid-row: 2/2;
                }
    
                &:nth-child(3) {
                    grid-column: 2/3;
                    grid-row: 2/3;
                }
    
                &:nth-child(4) {
                    grid-column: 3/5;
                    grid-row: 1/3;
                }
            }
            
        }
        .cms-module-categorybanner-item {
            overflow: hidden;

            @include desktop {
                &:nth-child(1) {
                    grid-column: 1/2;
                    grid-row: 1/2;
                }

                &:nth-child(2) {
                    grid-column: 2/3;
                    grid-row: 1/2;
                }

                &:nth-child(3) {
                    grid-column: 1/3;
                    grid-row: 2/3;
                }

                &:nth-child(4) {
                    grid-column: 3/5;
                    grid-row: 1/3;
                }
            }

            @include tablet {
                &:nth-child(1) {
                    grid-column: 1/2;
                    grid-row: 1/2;
                }

                &:nth-child(2) {
                    grid-column: 2/3;
                    grid-row: 1/2;
                }

                &:nth-child(3) {
                    grid-column: 1/2;
                    grid-row: 2/3;
                }

                &:nth-child(4) {
                    grid-column: 2/3;
                    grid-row: 2/3;
                }
            }
            &-txt {
                position: absolute;
                padding: 3rem;

                @include desktop {
                    padding: 0 8rem;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
        transform: scale(1);
        transition: all ease .4s;

        @at-root .cms-module-categorybanner-item:hover & {
            opacity: .8;
        }
    }

    &-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: 2;
        background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &-content {
        position: absolute;
        bottom: 30px;
        left: 30px;
        z-index: 3;
        color: var(--body-content-color-light);

        h4 {
            font-size: 20px;
            text-transform: uppercase;
            margin: 0 0 6px;
            color: var(--body-content-color-light);
            display: inline-block;
            
            @include desktop {
                font-size: 24px;
            }

            &::after {
                position: absolute;
                bottom: -3px;
                right: 0;
                width: 100%;
                height: 1px;
                background: #fff;
                z-index: 6;
                content: '';
                transform: scaleX(0);
                opacity: .4;
                transform-origin: right;
                transition: transform .7s cubic-bezier(.19, 1, .22, 1) .2s;
                transition-delay: 0s;

                @at-root .cms-module-categorybanner-item:hover & {
                    transform: scaleX(1);
                    transform-origin: left;
                }
            }
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
        }
    }

    &-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 4;
    }

    &.full {
        @include mobile {
            height: auto !important;
        }
        .cms-col.cms-col-3 {
            width: 100%;

            @include desktop {
                width: 25%;
            }
        }
        .cms-row {
            width: 100%;
            margin: 0 auto;
        }
        .cms-col {
            padding: 0 !important;
            height: 100%;

            @include mobile {
                height: auto;
            }
        }
        .cms-module-categorybanner {
            &-item {
                height: 100%;
                overflow: hidden;

                @include mobile {
                    height: 60vh;
                    z-index: 0;
                }
            }
        }
    }

    // Colourful Changes Features
    .cms-cf-container {
        height: 60vh;

        @include desktop {
            padding: 0 var(--content-v-padding);
            height: 70vh;
        }

        &-content {
            background: var(--picment-color-2-2);
            height: 100%;
            position: relative;

            @include desktop {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &-txt {
            text-align: center;
            padding: var(--content-v-padding) 0;
            
            @include desktop {
                padding: 20vh 0 var(--content-v-padding) 0;
            }

            h2 { 
                font-size: 16rem; 
                color: var(--picment-color-4-3);
                margin-top: 0;
            }
        }

        &-title {
            font-family: 'windsong';
            text-align: center;
            font-size: 2rem;
            color: var(--picment-color-2-1);
            margin-top: -2rem;
            z-index: 2;

            @include desktop {
                margin-top: -5rem;
                font-size: 5rem;
            }
        }
    }

    // Large Image Feature with Logo and txt
    .cms-full-feature {
        background: var(--picment-color-1-2);

        &-p { 
            color: black;        
        }

        &-inner {
            height: 50vh;

            @include desktop {
                margin: 0 var(--content-v-padding);
                height: 80vh;
            }
        }

        &-content {
            text-align: center;
            margin: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 90%;

            @include desktop {
                width: 25%;
            }
            
            .cms-logo {
                margin-top: -8rem;
            }
        }

        &-description {
            padding: var(--cms-col-content-x1);
            max-width: var(--narrow-content-width);
            margin:auto;
            text-align: center;
        }
    }

    // Popular Categories - 6 columns
    .cms-popular-categories-container {
        background: var(--picment-color-4-1);
        padding: 0 0 2rem 0;

        .cms-popular-categories {
            padding: 0 5px;
            margin: 5px auto 25px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.5rem;
            height: 60vh;

            @include desktop {
                padding: 0 var(--content-v-padding);
                grid-template-columns: repeat(6, 1fr);
                height: 35vh;
            }

            &-item {
                cursor: pointer;
            }
            &-item:hover {
                transform: scale(1.07);
                z-index: 3;
            }

            &-content {
                padding: 1.5rem;
                bottom: 0;
                position: absolute;
                max-width: 100%;
                z-index: 3;

            }
            h4 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: white;
            }
        }
        .cms-popular-categories-txt {
            text-align: center;
            padding: 2rem;
            h2 {
                margin-bottom: 0;
            }
        }
    }

    // Main / Department Categories
    .dpt-image { 
        border-radius: 100%; 
        border: 3px solid transparent;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        // z-index: 10;
        width: 100%;

        .Image-Image {
            object-fit: unset;
        }

        &:hover {
            border: 3px solid var(--picment-color-3-1);
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            // z-index: 10;
            cursor: pointer;
        }
    }
    .dpt-txt { 
        text-align: center; 
        font-size: 1.5rem;
    }
    .dtp-items { 
        margin: 0.2rem; 
        width: 35vw;

        p {
            margin-bottom: 0;
        } 
        @include desktop {
            width: calc(100% - 0.4rem);
        }
        @include tablet {
            width: 100%;
        }
    }
    .dpt-row { 
        display: grid; 
        grid-template-columns: repeat(5, 1fr); 
        width: 100%; 
        margin: auto; 
        padding: 8rem 0;
        grid-gap: 10px;

        @include desktop {
            max-width: var(--content-wrapper-width);
            grid-gap: 8rem;
        }
        @include mobile {
            padding: 4rem 0;
            &-scroll {
                overflow: auto;
                white-space: nowrap;
            }
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
}