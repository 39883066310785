.accordion {
    border-radius: 5px;
    overflow: hidden;
    border-top: 1px solid rgba(0,0,0,.07);
    &:last-child {
        border-bottom: 1px solid rgba(0,0,0,.07);
    }
    &-label {
        display: flex;
        padding: 25px 0;
        padding-right: 30px;
        font-size: 1.2rem;
        font-weight: 100;
        cursor: pointer;
        color: var(--body-heading-color);
        letter-spacing: var(--heading-letter-spacing);
        position: relative;
        &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            content: '';
            display: block;
            background-color: transparent;
            height: 20px;
            width: 20px;
            background-image: url(icons/plus.svg);
            transition: all ease .3s;
            bottom: unset;
        }
    }
    &-content {
        max-height: 0;
        cursor: pointer;
        font-size: 1.2rem;
        font-size: var(--p-font-size);
        font-weight: 100;
        letter-spacing: var(--heading-letter-spacing);
        color: var(--body-heading-color);
    }
    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
    input:checked {
        & ~ .accordion-content {
          max-height: 100vh;
          padding-top: 0;
          padding-bottom: 25px;
        }
        & ~ .accordion-label {
            &::after {
                background-image: url(icons/minus.svg);
            }
          }
    }
  }