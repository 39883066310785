/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Footer {
    &-Top {
        padding: var(--content-v-padding);
        background: var(--theme-color-4);
        color: white;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 4rem 0;

        &-ContentSection {
            display: grid;

            &:nth-of-type(1) {
                justify-content: center;
            }

            &:nth-of-type(2) {
                grid-template-columns: repeat(3, 1fr);
                justify-items: center;
            }
        }
    }

    &-TopSection {
        .footer-icons {
            .Image {
                width: 40px;
                height: 40px;
                border: 1px solid rgba(0, 0, 0, 1);
                padding: 0;
                margin-right: 10px;
                border-radius: 50%;

                img {
                    height: 20px !important;
                    width: 20px !important;
                    display: block;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &_type {
            &_trustpilot {
                padding: 50px 0;
                border-bottom: 1px solid var(--theme-border-color);

                @include phone {
                    .CmsBlock-Wrapper {
                        padding-left: 0;
                        padding-right: 0;

                        .cms-section-container {
                            width: 100%;
                            max-width: 100%;

                            .cms-col {
                                padding-left: 0;
                                padding-right: 0;

                                .cms-col-inner {
                                    padding: 0;

                                    .Trustpilot {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &_customerservice {
                background-color: white;

                padding: 70px 0 100px 0;

                @include phone {
                    padding: 50px 0 0;
                }
            }

            &_newletter {
                background: var(--picment-color-2-2);
                text-align: center;
                padding: 4rem;

                @include desktop {
                    p {
                        width: 60%;
                        margin: auto auto 25px;
                    }
                }
            }

            &_pages {
                background: var(--picment-color-4-3);
                padding: 4rem;
            }

            &_stores {
                background: var(--picment-color-2-1);
                padding: 50px 0;
                color: #fff;

                h5 {
                    margin-bottom: .6rem;
                    font-weight: 700;
                }

                p,
                a {
                    color: #fff;
                }

                p {
                    font-weight: 500;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .CmsBlock-Wrapper {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 50px;

                    @include phone {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }

        &_isFullWidth {
            .CmsBlock-Wrapper {
                max-width: var(--content-wrapper-width);
                width: 100%;
                padding-left: 1.2rem;
                padding-right: 1.2rem;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &-Store {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        @include phone {
            grid-template-columns: 10fr 12fr;
        }
    }

    &-StoreIcon {
        .Image {
            .Image-Image {
                left: unset;
                right: 20px;
            }
        }
    }

    &-CustomerSupportHeader {
        text-align: center;

        h2 {
            margin-bottom: .6rem;
        }
    }

    &-CustomerSupportBoxes {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 2.4rem;

        @include phone {
            grid-template-columns: 1fr;
        }

        @include desktop {
            margin-top: 70px;
        }
    }

    &-CustomerSupportBox {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 40px 80px 30px;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-right: 1px solid rgba(0, 0, 0, .1);

        @include phone {
            border: none;
            grid-template-rows: 30px 80px 20px;
            margin-bottom: 2rem;
            padding-bottom: 2rem;

            h5 {
                margin: 0;
            }

            .subtext {
                margin: 0;
            }
        }

        &:not(:last-child) {
            &::after {
                @include phone {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 80%;
                    height: 1px;
                    background: rgba(0, 0, 0, .1);
                }
            }
        }

        h4 {
            margin: 0;
        }

        .Image {
            .Image-Image {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .phonenumber {
            font-size: 3rem;
            font-weight: 600;

            a {
                color: #676464;
            }
        }

        .subtext {
            font-size: 1.1rem;
        }

        &:last-child {
            border: none;
        }
    }

    &-CmsBlockWrapper {
        background: var(--picment-color-4-1);
        border-top: 1px solid var(--theme-border-color);
    }

    &-Columns {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;

        &_type {
            &_main-footer {
                padding: 4rem 0;
                max-width: var(--content-wrapper-width);

                ul.links {
                    grid-template-columns: repeat(3, 1fr);
                    display: grid;

                    @include desktop {
                        padding-left: 25px;
                        border-left: 1px solid rgba(0, 0, 0, 0.1);
                    }
                    

                    //@include mobile {
                    //    justify-content: center;
                    //    text-align: center;
                    //}

                    li {
                        margin: 0 40px 0 0;

                        a {
                            text-transform: uppercase;
                            font-size: 1.1rem;
                            letter-spacing: .06rem;
                            font-weight: 600;
                            color: var(--picment-color-2-1);
                        }
                        &:nth-child(2n):after {
                            content: "\A";
                            white-space: pre;
                            width: 50px;
                        }

                        &:last-child {
                            margin-right: 0;

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

   &-Shortcuts {
       display: flex;
       //flex-direction: column;
       //align-items: flex-start;
       //justify-content: center;

       ul.links {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin: auto;

            @include mobile {
                justify-content: center;
                text-align: center;
            }
        

        //@include mobile {
        //    justify-content: center;
        //    text-align: center;
        //}

        li {
            margin: 0 10px 0 0;
            padding: 0;

            a {
                text-transform: uppercase;
                font-size: 1.1rem;
                letter-spacing: .06rem;
                font-weight: 600;
                color: var(--picment-color-2-1);
            }

            &::before {
                content: '';
            }

            &::after {
                content: '-';
                margin-left: 10px;
            }

            &:last-child {
                margin-right: 0;

                &::after {
                    display: none;
                }
            }
        }
    }
}

    //&-ShortcutsTitle {
    //    display: inline-block;
    //    border-bottom: 1px solid rgba(0, 0, 0, .15);
    //    padding-bottom: 1.2rem;
//
    //    @include mobile {
    //        margin-left: auto;
    //        margin-right: auto;
    //    }
    //}

    &-ContentColumns {
        border-bottom: 1px solid var(--theme-border-color);
        text-align: left;
        @include desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 50px;
            max-width: var(--content-wrapper-width);
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }   
    }

    &-ContentColumn {
        &:nth-of-type(2) {
            display: flex;
            justify-content: space-between;

            @include mobile {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 2rem;
            }
        }

        &:nth-of-type(3) {
            display: flex;
            justify-content: space-between;

            @include mobile {
                display: grid;
                grid-template-columns: 1fr;
            }
        }

        &:last-child {
            border: none;
        }
    }

    &-ContentSection {
        margin-bottom: 2.4rem;
        width:100%;

        .practical-gfx {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            max-width: var(--content-wrapper-width);
            margin: 2rem auto;
            @include mobile {
                grid-template-columns: 1fr;
            }
        }

        .footer-betaling {
            display:block;
            margin-left:auto;
            text-align: right;
        }

        .fine-print {
            font-size: 1rem;
        }

        .Image {
            padding: 0;
            height: auto;
            width: auto;

            img {
                position: relative;
            }
        }

        .links {
            margin-left: auto;
            li {
                padding: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    .trustpilot-widget {
        @include mobile {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        iframe {
            @include phone {
                width: 300px !important;
                max-width: 1000px;
            }
        }
    }

    .NewsletterSubscription {
        > form {
            > .FieldForm-Fieldset {
                @include mobile {
                    justify-content: center;
                }
            }
        }
    }
}

.footer2022 {
    display: flex;
    gap: 2em;
    margin-top: 2rem;
    flex-wrap: wrap;
    justify-content: flex-end;

    &_right-btm {
        display: grid;
        grid-template-rows: 70%;
        position: absolute;
        right: 0;
    }

    &_item {
        flex-basis: 100%;
        text-align: center;
        margin-top: 2em;

        @include desktop {
            text-align: left;

            &:first-child {
                flex-basis: 50%;
                margin-right: auto;
            }
            &:last-child {
                display: flex;
                flex-basis: 100%;

                & > div {
                    flex-basis: 50%;

                    &:last-child {
                        flex-basis: calc(30% + 2em);
                        margin-left: auto;
                    }
                }
            }
        }

        &--pad-left {
            padding-left: 2rem;

            @include desktop {
                padding-left: 0;
            }
        }
        
    }

    &_logo {
        margin-bottom: 2rem;

        @include desktop {
            margin-bottom: 0;
        }
    }

    &_heading {
        letter-spacing: var(--heading-letter-spacing);
        font-size: calc(var(--h3-font-size) / 1.2);
        // font-weight: var(--header-font-weight);
        font-weight: 600;

        @include desktop {
            font-size: var(--h3-font-size);
        }
    }

    &_nav {
        margin-left: 1rem;

        li {
            font-size: 1.4rem;
            padding: 0;
            margin: 0;

            &::before {
                content: none;
            }
        }
    }

    &_contact-text p {
        font-size: 1.2em;
    }
}

.footer2022_item:not(.footer2022_item:first-child, .footer2022_item:last-child) {
    flex-basis: 44%;
    text-align: left;

    @include desktop {
        flex-basis: 15%;
    }
}
