/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Field {

    @at-root .isCheckout {
        --input-border-color: #c9c9c9;
        --option-border-color: var(--input-border-color);
        --input-border-color-focus: var(--input-border-color)!important;
    }

    @at-root body .CheckoutAddressForm & {
        @include desktop {
            padding: 0 .6rem;
        }
    }

    [type='number'] {
        font-weight: 500;
    }

    [type='file'] {
        &#customizable-options-file {
            height: 100px;
        }

        + label {
            &[for='customizable-options-file'] {
                display: grid;
                grid-template-columns: 2fr 3fr;
                grid-template-rows: repeat(2, auto);
                flex-direction: unset;
                height: 100px;

                .Image {
                    grid-column: 1 / 2;
                    grid-row: 1 / 3;

                    img {
                        object-position: 50% 100%;
                        width: 60px !important;
                        left: unset;
                        right: 20px;
                    }
                }

                p {
                    align-self: flex-end;
                }

                span {
                    align-self: flex-start;
                }
            }
        }
    }

    &-Label {
        &_isRequired {
            &::after {
                content: ' *';
            }
        }

        @at-root .isCheckout & {
            font-weight: 400;
            padding-bottom: 0;
            font-size: 1.1rem;
        }
    }

    &-Message {
        @at-root .isCheckout & {
            font-size: 1.1rem;
            font-weight: 500;
            color: #792727;
        }
    }

    &_isValid {
        input,
        textarea {
            border: 1px solid var(--option-border-color);
            background: transparent;
        }

        [type='file'] {
            + label {
                &[for='customizable-options-file'] {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    > p {
                        align-self: unset;
                    }
                }
            }
        }
    }

    &_hasError {
        input,
        textarea {
            @at-root .isCheckout & {
                border: 1px solid var(--input-border-color);
            }

            &[value=""] {
                + .Field-Message {
                    @at-root .isCheckout & {
                        display: none;
                    }
                }
            }
        }
    }
}

.FieldForm-Fields {
    @at-root body .CheckoutAddressForm & {
        @include desktop {
           margin: 0 -.6rem;
        }
    }

    @at-root .CheckoutGuestForm & {
        margin-bottom: .6rem;
    }
}


