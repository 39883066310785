.cms-module-text {

    a {
        text-decoration: underline;
    }
    
    &.narrow {
        max-width: var(--narrow-content-width);
        margin: 0 auto;
    }
    &.nunito h1 { 
        font-family: 'Nunito';
        font-size: 3rem;
    }
}